import React from "react";
import { Container } from "../components/container";
import Link from "next/link";

type HowItWorksProps = {};

const HowItWorks: React.FC<HowItWorksProps> = ({}) => {
  return (
    <Container containerClassName="py-8" maxWidth="1000px">
      <div className="md:py-16 flex flex-col lg:flex-row items-center lg:items-stretch text-md md:text-lg">
        <h2
          className="w-full lg:w-1/3 text-2xl lg:text-5xl leading-tight text-green-400 block lg:hidden font-semibold"
          style={{ maxWidth: 700 }}
        >
          So funktioniert Tierversand
        </h2>
        <h2
          className="lg:w-1/3 text-3xl lg:text-5xl leading-tight text-green-400 hidden lg:block"
          style={{ maxWidth: 700 }}
        >
          So funktioniert
          <br />
          Tierversand
        </h2>
        <div className="w-full lg:w-3/5" style={{ maxWidth: 700 }}>
          <p className="mt-2 lg:mt-3">
            Nachdem Sie den Versand über unsere Webseite gebucht haben erhalten
            Sie eine Bestätigungsmail mit einer Checkliste zum Tierversand.
          </p>
          <p className="mt-2 lg:mt-3">
            Mit diesen Infos können Sie Ihr Tier fit für einen sicheren und
            schonenden Transport machen.
          </p>
          <p className="mt-2 lg:mt-3">
            Am Nachmittag zwischen 12 und 18 Uhr Ihres Wunschdatums holt ein
            Fahrer Ihr gut verpacktes Tier bei Ihnen an der Haustür ab.
          </p>
          <p className="mt-2 lg:mt-3">
            Über Nacht wird es sorgsam an den Zielort gebracht. Dort wird es
            dann am frühen Morgen zwischen 8 und 12 Uhr an den Empfänger
            Übergeben.
          </p>
        </div>
      </div>
      <div className="flex flex-wrap md:flex-no-wrap mt-4 md:mt-0">
        <Link href="/checkliste-fuer-den-tierversand">
          <a>
            <div className="text-green-400 border text-center border-green-400 font-semibold rounded px-2 py-1 md:mx-1 my-1 md:my-0 w-full md:w-auto">
              Checkliste für den Tierversand
            </div>
          </a>
        </Link>
        <Link href="/so-funktioniert-lebend-tierversand">
          <a>
            <div className="text-green-400 border text-center border-green-400 font-semibold rounded px-2 py-1 md:mx-1 my-1 md:my-0 w-full md:w-auto">
              So funktioniert Tierversand
            </div>
          </a>
        </Link>
      </div>
    </Container>
  );
};

export default HowItWorks;
