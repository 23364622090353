import React from "react";

interface FAQProps {}

const FAQ: React.FC<FAQProps> = ({}) => {
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 className="text-3xl leading-9 font-extrabold text-green-400 text-center">
          Häufig gestellte Fragen zum Tierversand
        </h2>
        <div className="mt-12">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Ist ein Tierversand ins Ausland möglich?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                In vielen Fällen können wir einen Tiertransport ins Ausland
                organisieren. Kontaktieren Sie uns dafür unter:{" "}
                <a
                  href="mailto:info@tierversand24.de"
                  target="_blank"
                  className="text-green-400 font-semibold"
                >
                  info@tierversand24.de
                </a>
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Bis wann muss Ich den Versand beauftragen?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                Bis 18 Uhr können Sie die Abholung ihrer Tiere zum Folgetag
                buchen.
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Kann man das Zeitfenster für die Abholung oder die Lieferung
                einschränken?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                In einigen Fällen ist das möglich. Kontaktieren Sie uns dafür
                vor der Buchung unter:{" "}
                <a
                  href="mailto:info@tierversand24.de"
                  target="_blank"
                  className="text-green-400 font-semibold"
                >
                  info@tierversand24.de
                </a>
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                An welchen Tagen ist ein Tierversand möglich ?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                Vögel, Geflügel und Säugetieren holen wir Montags bis Mittwoch
                ab.
                <br />
                Reptilien, Fische und Insekten von Montags bis Donnerstags.
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Werden die Fahrzeuge beheizt oder gekühlt?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                Unsere Fahrzeuge werden nicht beheizt.
                <br />
                Manche Tierarten benötigen eher Kühle, andere eher warme
                Temperaturen. Daher muss die Versandbox dem Wetter und der
                Tierart entsprechend isoliert und ggf. mit Heat oder Kühlpacks
                ausgestattet sein.
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Kann Ich meinen Auftrag im Nachhinein ändern oder stornieren?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                Ja, kontaktieren Sie uns dafür unter:{" "}
                <a
                  href="mailto:info@tierversand24.de"
                  target="_blank"
                  className="text-green-400 font-semibold"
                >
                  info@tierversand24.de
                </a>
                . Adress- oder Datumskorrekturen sind kostenlos. Stornierungen
                sind gegen die in unseren AGB gelistete Gebühr möglich.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export { FAQ };
