import React from "react";
import Image from "next/image";
import Link from "next/link";
import { Container } from "../components/container";

type TransportableContentClassesProps = {};

const TransportableContentClasses: React.FC<TransportableContentClassesProps> = ({}) => {
  const transportableSpecies = [
    { src: "/img/birds.png", name: "Vögel", to: "/voegel-versenden" },
    {
      src: "/img/poultry.png",
      name: "Tauben + Geflügel",
      to: "/gefluegel-versenden",
    },
    {
      src: "/img/mammals.png",
      name: "Säugetiere",
      to: "/saeugetiere-versenden",
    },
    { src: "/img/fish.png", name: "Fische", to: "/fische-versenden" },
    { src: "/img/insects.png", name: "Insekten", to: "/insekten-versenden" },
    { src: "/img/reptiles.png", name: "Reptilien", to: "/reptilien-versenden" },
  ];

  return (
    <Container containerClassName="bg-white py-16">
      <section className="mx-auto">
        <h1 className="text-center text-2xl md:text-3xl text-green-400 font-semibold">
          Welche Tierart wollen Sie versenden?
        </h1>
        <div className="flex flex-wrap">
          {transportableSpecies.map((contentClass) => (
            <Link key={contentClass.name} href={contentClass.to}>
              <a
                title={`${contentClass.name} versenden - Infos`}
                key={contentClass.name}
                className="w-1/2 md:w-1/3 p-2 cursor-pointer"
              >
                <div className="rounded border border-gray-300 shadow hover:shadow-lg">
                  <Image
                    key={contentClass.name}
                    src={contentClass.src}
                    alt={contentClass.name}
                    className="w-full object-cover"
                    width="100%"
                    height="64rem"
                    layout="responsive"
                  />
                  <div className="p-2 text-center font-semibold">
                    {contentClass.name}
                  </div>
                </div>
              </a>
            </Link>
          ))}
        </div>
      </section>
    </Container>
  );
};

export { TransportableContentClasses };
