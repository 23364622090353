import Head from "next/head";
import { Header } from "../components/header";
import { withApollo } from "../lib/with-apollo";
import Advantages from "../sections/advantages";
import { BusinessOffer } from "../sections/business-offer";
import { FAQ } from "../sections/faq";
import { Footer } from "../sections/footer";
import { HeroBook } from "../sections/hero-book";
import HowItWorks from "../sections/how-it-works";
import { TopReviews } from "../sections/top-reviews";
import { TransportableContentClasses } from "../sections/transportable-animal-classes";

const Index: React.FC = () => {
  return (
    <div className="bg-gray-50 text-gray-700">
      <Head>
        <title>Tierversand vom Profi</title>
        <meta property="og:title" content="Tierversand vom Profi" key="title" />
        <meta
          property="og:description"
          name="description"
          content="Wir versenden Ihre Tiere schnell und sicher. Mit unserem Versandkonfigurator buchen Sie Ihren Tierersand in wenigen Augenblicken"
        />
        <meta
          name="keywords"
          content="Tierversand, Tiertransport, Tiere versenden, Tiere verschicken, Lebendtierversand, Günstiger Tierversand"
        />
      </Head>
      <Header />
      <main>
        <HeroBook bgImage="all" />
        <Advantages />
        <HowItWorks />
        <TransportableContentClasses />
        <TopReviews />
        <FAQ />
        <BusinessOffer />
        <Footer />
      </main>
    </div>
  );
};

export default withApollo()(Index);
